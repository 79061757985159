import React from 'react'
import Footer from '../../components/footer'
import Header from '../../components/header'
import SEO from '../../components/SEO'
import '../../styles/vpn-provider.less'
import { EbookPageForm } from '../../components/form-biz'
// import { lang } from '../../utils'
import { Button } from '../../components/ui'
import { navigate } from 'gatsby'
const loadText = require('src/utils').loadText
// const data = loadText('vpn-provider')

export const TopBanner = ({ isBanner = false }: { isBanner?: boolean }) => (
  <div className={`vpn-provider-topBanner ${isBanner ? 'banner' : ''}`}>
    <div className="vpn-provider-topBanner-bg">
      {!isBanner && (
        <div className="headerContainer ">
          <Header logo="/logo-blue.svg" />
        </div>
      )}
      <div className="vpn-provider-topBanner-content">
        <div className="left">
          <div className="title">For VPN providers</div>
          <div className="subtitle">2023 VPN Infrastructure Buyer's Guide</div>
          {isBanner && (
            <Button
              size="l"
              width={400}
              onClick={() => {
                navigate('/lp/vpn-provider/')
              }}
            >
              Download the white paper
            </Button>
          )}
        </div>
        {/* <div className="right">
          <img
            src="/vpn-provider/topBanner-pic.png"
            alt="topBanner-pic"
            className="topBanner-pic"
          />
        </div> */}
      </div>
    </div>
  </div>
)
const Form = () => (
  <div className="vpn-provider-form">
    <div className="vpn-provider-form-bg">
      <div className="vpn-provider-form-content">
        <div className="left">
          {/* {data.form.content.paragraphs.map((p) => ( */}
          <div>
            Competition is heating up in the global virtual private network
            (VPN) market, making it exceedingly difficult for VPN providers to
            stand out and win customers. Zenlayer helps VPN providers modernize
            their infrastructure and offer services that align more closely with
            the changing needs of today’s businesses and end users.
          </div>
          <div>
            Zenlayer’s 2023 VPN Infrastructure Guide offers an updated overview
            of the VPN market, including how Zenlayer is helping companies
            evolve to become more agile, scalable, and secure.
          </div>
          {/* ))} */}
          <ul>
            <li>Download your free copy to learn:</li>
            <li>The top VPN industry trends to be aware of in 2023</li>
            <li>VPN market spotlight: Indonesia</li>
            <li>Common infrastructure challenges facing VPN providers</li>
            <li>
              How Zenlayer helps VPN providers overcome challenges and scale
              their services.
            </li>
          </ul>
        </div>
        <div className="right">
          <EbookPageForm
            btnText="Download the white paper"
            source="vpn-provider"
            onSuccess={() => {
              const link = document.createElement('a')
              const file = '/VPN Whitepaper.pdf'
              link.href = `${file}`
              link.download = file
              link.dispatchEvent(new MouseEvent('click'))
            }}
          />
        </div>
      </div>
    </div>
  </div>
)

export default function Home() {
  return (
    <div className="vpn-provider">
      <SEO
        // {...data.seo}
        title="VPN Summary Whitepaper | Zenlayer"
        description=""
        featuredImage="/featuredImage/zenlayer.jpg"
        noIndex={true}
      />
      <TopBanner />
      <Form />
      <Footer />
    </div>
  )
}
